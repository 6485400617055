@import "../stylesheet.scss";

.home {
  .section-1,
  .section-2,
  .section-3 {
    max-width: 1000px;
    margin: 0 auto;
  }

  .section-1 {
    padding: 20px 15px 0;

    .heading {
      margin: 16px 0;
      font-weight: 300;
    }

    .body {
      font-size: 1.25rem;
      margin: 0 0 50px 0;
    }

    .join-us {
      position: relative;
      width: 100%;
      overflow: hidden;
      border-radius: 5px;

      .img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: linear-gradient(
          to right,
          rgba(10, 93, 189, 0.4) 20%,
          rgba(0, 0, 0, 0.2) 100%
        );
        // background: linear-gradient(
        //   to right,
        //   rgba(10, 93, 189, 0.35) 13.33%,
        //   rgba(0, 0, 0, 0.12) 100%
        // );
      }

      .content {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: calc(1.325rem + 0.75vw);
        box-sizing: border-box;
        color: white;
      }

      .copy {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .heading {
          font-size: 30px;
          font-weight: 900;
          margin: 0;
        }

        .subheading {
          font-size: 16px;
          font-weight: 300;
          max-width: 300px;
          margin: 0 0 16px 0;
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn-link {
          margin: 5px 0;
        }
      }

      .link {
        color: white;
      }
    }
  }

  .section-2 {
    padding: 20px 15px;

    .container {
      background-color: $primary-color;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-radius: 5px;
    }

    .col-1 {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .col-2 {
      display: flex;
      flex-direction: column;
      padding: 6rem 4rem 4rem;
      align-items: left;
      text-align: left;
      height: 100%;

      .heading {
        font-weight: 800;
        margin: 0 0 15px;
        color: white;
      }

      .body,
      .link {
        color: white;
      }

      .btn-video,
      a {
        color: #0a5dbd;
      }
    }
  }

  .section-3 {
    padding: 20px 15px 100px;

    .copy {
      display: block;
      text-align: flex-start;
      margin: 0 0 30px 0;
    }

    .heading {
      margin: 16px 0;
      font-weight: 300;
    }
  }
}

@media (max-width: $bs-breakpoint-lg) {
  .home {
    .section-2 {
      padding: 20px 15px 50px;

      .container {
        display: flex;
        flex-direction: column;
      }

      .col-2 {
        text-align: center;
        align-items: center;
        padding: 2rem;
      }
    }
  }
}

@media (max-width: $bs-breakpoint-md) {
  .home {
    .section-1 {
      .body {
        font-size: 1.1rem;
      }

      .join-us {
        overflow: hidden;

        .overlay {
          background: transparent;
        }

        .img {
          display: none;
        }

        .content {
          display: block;
          background-color: #013691;
          color: white;

          .copy {
            .subheading {
              max-width: -webkit-fill-available;
            }
          }
        }

        .btn-link {
          display: block;
          width: -webkit-fill-available;
          text-align: center;
          border: 1px solid white;
        }
      }
    }
  }
}
