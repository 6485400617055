@import "../stylesheet.scss";

.post-solo {
  @include marginals;

  ul {
    color: $secondary-color;
    font-weight: 200;
    margin: 0 0 0 15px;
    padding: 0;
  }
}
