@import "../stylesheet.scss";

.faqs {
  @include marginals;

  hr {
    height: 1px;
    background-color: $secondary-color;
    border: none;
  }

  .subheading {
    margin: 0 0 50px 0;
  }

  .toggle {
    color: $primary-color;
    font-size: 2rem;
    font-weight: 200;
  }

  .question-container {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    padding: 2px 0;
    width: 100%;
    font-family: "Urbanist", sans-serif;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 300;
      margin: 0 15px 0 0;
      color: $primary-color;
    }

    &:hover .question,
    &:hover .toggle {
      font-weight: 400;
      transition: 0.15s all ease-in;
    }
  }

  .content-container {
    max-height: fit-content;
    overflow: hidden;
    font-weight: 300;
    font-size: 1rem;
    color: $primary-color;

    .content {
      margin: 0 0 15px;

      ul {
        margin: 0 0 15px 0;
        padding: 0;
        list-style-type: none;

        li {
          padding: 5px 0;
        }
      }
    }
  }
}
