@import "../stylesheet.scss";

.soaring {
  @include marginals;

  // .caption {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .name {
    margin: 0 0 10px 0;
    padding: 0;
  }
}

@media (max-width: $bs-breakpoint-sm) {
  .soaring {
    .card {
      .container {
        display: flex;
        flex-direction: column;
      }

      .caption {
        margin: 10px 0;
      }
    }
  }
}
