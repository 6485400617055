@import "../stylesheet.scss";

.aerobatics {
  @include marginals;

  .body {
    margin: 0;
  }

  .caption {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aerobatics .caption {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $bs-breakpoint-md) {
  .aerobatics {
    .card {
      .container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
