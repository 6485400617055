@import "../stylesheet.scss";

.sitemap {
  @include marginals;

  label {
    display: block;
    font-size: 1.5em;
    color: white;
    background: $primary-color;
    width: fit-content;
    margin: 1.5rem 0;
    padding: 0.2rem 0.5rem;
    line-height: 1.3;
  }

  .subheading {
    font-size: 1.2em;
    color: $primary-color;
    background-color: $bg-color;
    border: 1px solid $primary-color;
    margin: 30px 0 5px 20px;
  }

  a {
    display: block;
    width: fit-content;
  }

  ul {
    margin: 0 0 0 50px;
    padding: 0;
    line-height: 2;
  }
}
