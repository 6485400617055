@import '../stylesheet.scss';

.video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: none;
    }
}