@import "../stylesheet.scss";

.decades {
  @include marginals;

  .caption {
    text-align: center;
    text-decoration: none;
    color: $primary-color;
    margin-top: 80px;
    font-weight: 800;
  }
}

.gallery {
  .decade {
    display: block;
    text-align: end;
    margin: 50px 0 0 0;

    hr {
      width: 100%;
      border: 1px solid $primary-color;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    max-width: 100%;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .data,
    .data-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 16 / 9;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.095);
      }
    }

    .data-loading {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border: 1px solid rgba(210, 210, 210, 0.265);
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;

      @keyframes shine {
        to {
          background-position-x: -200%;
        }
      }
    }
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    &.show {
      opacity: 1;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    max-height: 90%;

    img {
      width: 100%;
      height: auto;
      max-width: 80vh;
      object-fit: contain;
    }

    .caption {
      text-align: center;
      color: white;
      font-size: 1.2rem;
      margin-top: 20px;
      font-weight: 300;
    }
  }

  .tools {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    background-color: rgb(17, 18, 20);

    .arrows {
      position: absolute;
      left: 20px;
      top: 15px;
      width: fit-content;
    }

    .btn-navigate {
      background-color: transparent;
      border: none;
      color: white;
      &:hover {
        cursor: pointer;
      }
    }

    .close {
      color: #ccc;
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: $bs-breakpoint-md) {
  .gallery {
    .items {
      max-width: 100%;
      max-height: 100%;

      .caption {
        font-size: 1rem;
        padding: 0 15px;
      }
    }
  }
}
