@import '../stylesheet.scss';

.costs {
    border-collapse: collapse;
    font-weight: 200;
    color: $primary-color;
 
    td {
        border: 0.5px solid #ddd;
        width: 33%;
        padding: 8px;
    }

    tbody {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .heading {
        color: white;
        background-color: $primary-color;
        font-weight: 300;
    }

    .note {
        color: rgba(128, 128, 128, 0.5);
        font-weight: 400;
    }
}

@media (max-width: $bs-breakpoint-md) {
    .costs {
        td {
            width: auto;
        }
    }
}