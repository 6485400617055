@import "../stylesheet.scss";

.navbar {
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 2%);
  z-index: 1;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $primary-color;
  }

  .menu-btn {
    display: none;
  }

  .brand {
    text-decoration: none;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      padding: 10px 15px;
      width: fit-content;

      .heading {
        margin: 0 0 0 10px;
        font-weight: 200;
        color: white;
      }

      .logo {
        height: 55px;
        width: auto;
      }
    }
  }

  .br {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }

  .nav-elements {
    display: flex;
    background-color: $bg-color;
    transition: background-color 1s ease-out;
    height: 50px;

    .nav-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style-type: none;
      align-items: center;
      padding: 0;

      .nav-item {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0 8px 0 0;
        position: relative;
        font-size: 1rem;
        font-family: "Urbanist", sans-serif;
        color: $primary-color;
        font-weight: 300;
        text-decoration: none;
        width: fit-content;
        height: 50px;
        &:hover {
          text-decoration: underline;
          text-underline-offset: 0.25rem;
        }

        .active {
          position: relative;
        }

        .active::after {
          content: "";
          position: absolute;
        }
      }
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    width: 300px;
    position: absolute;
    margin: 1px 0 0 0;
    background-color: $primary-color;
    z-index: 1;

    .item {
      font-weight: 300;
      color: white;
      padding: 12px;
      text-decoration: none;
      &:hover {
        background-color: rgba(255, 255, 255, 0.211);
        transition: ease 0.3s background-color;
        text-decoration: underline;
        text-underline-offset: 0.25rem;
      }
    }
  }

  .social-links {
    display: flex;
    align-items: center;

    .link {
      color: white;
      background-color: $primary-color;
      border: 0.5px solid $primary-color;
      display: flex;
      padding: 5px;
      margin: 0 4px;
      border-radius: 50%;
      &:hover {
        color: $primary-color;
        background-color: white;
        border: 0.5px solid $primary-color;
        transition: all 0.15s ease-in;
      }
    }

    span {
      display: none;
    }
  }
}

@media (max-width: $bs-breakpoint-md) {
  // sidebar menu element
  .navbar {
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .brand {
      .container {
        border: none;
        padding: 20px 15px;
      }
    }

    .menu-btn {
      color: white;
      display: block;
      cursor: pointer;
      margin: 0 15px 0 0;
      &:hover {
        background-color: rgba(255, 255, 255, 0.158);
        transition: 0.15s background-color;
        border-radius: 5px;
      }
    }

    .br {
      display: block;
      width: 100%;
      padding: 0;
    }

    .nav-elements {
      background-color: $primary-color;
      position: fixed;
      top: 0;
      right: 0;
      width: 0;
      height: 100vh;
      overflow: scroll;
      transition: width 0.45s ease-in-out;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      .nav-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .nav-item {
          display: flex;
          justify-content: space-between;
          width: 300px;
          padding: 0 10px;
          margin: 0;
          color: white;
          border-bottom: 1px solid rgba(70, 131, 180, 0.385);
          &:hover {
            text-decoration: underline;
            color: white;
          }
        }
      }
    }

    .nav-elements.active {
      height: 100vh;
      width: 300px;
      background-color: $primary-color;
      transition: width 0.45s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
      right: 0;
      overflow-x: hidden;

      .social-links {
        display: flex;
        flex-direction: column;
        align-items: center;

        .link {
          color: white;
          align-items: center;
          border-bottom: 1px solid rgba(70, 131, 180, 0.385);
          display: flex;
          padding: 0px 10px;
          margin: 0;
          border-radius: 0;
          height: 50px;
          width: -webkit-fill-available;
          &:hover {
            text-decoration: underline;
            color: white;
            background-color: transparent;
          }
        }

        span,
        a {
          display: block;
          margin: 0 0 0 5px;
          text-decoration: none;
          font-weight: 300;
        }
      }
    }

    .nav-elements.false {
      border: none;
    }

    .dropdown {
      position: relative;
      width: 100%;
      padding-left: 10px;
      padding: 0;
      margin: 0;
      background-color: rgba(70, 131, 180, 0.2);

      .item {
        display: flex;
        background-color: rgba(70, 131, 180, 0.2);
        margin: 0;
        padding: 8px 10px;
        height: 30px;
        align-items: center;
        border-bottom: 1px solid rgba(70, 131, 180, 0.385);
        &:hover {
          color: white;
          background-color: transparent;
        }
      }
    }
  }
}

@media (max-width: $bs-breakpoint-sm) {
  .navbar {
    .heading {
      font-size: 1.5rem;
      inline-size: 180px;
    }
  }
}

@media (max-width: $bs-breakpoint-xs) {
  .navbar {
    .heading {
      display: none;
    }
  }
}
