@import "../stylesheet.scss";

.what-is-gliding {
  @include marginals;

  .subheading {
    margin: 0 0 10px 0;
    padding: 0;
  }
}

@media (max-width: $bs-breakpoint-md) {
  .what-is-gliding {
    .card {
      .container {
        display: contents;

        .subheading {
          margin: 10px 0;
        }
      }
    }
  }
}
