@import "../stylesheet.scss";

.location {
  @include marginals;

  .address {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }
  }
}

@media (max-width: $bs-breakpoint-md) {
  .location {
    .card {
      .container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
